// This is the scss entry file
import "../styles/index.scss";
import htmx from 'htmx.org/dist/htmx';
import Alpine from 'alpinejs';
import Swiper from 'swiper';

// We can import Bootstrap JS instead of the CDN link, if you do not use

// We can import other JS file as we like
import "../components/sidebar";

// initialize htmx
window.htmx = htmx;

// Alpine JS Scripts
window.Alpine = Alpine;
Alpine.start();

// initialize swiper.js
window.Swiper = Swiper;


window.document.addEventListener("DOMContentLoaded", function () {
  window.console.log("dom ready 1");
});
